import React from "react"
import styled from "styled-components"

import gold from "../../../assets/images/gold.svg"
import bronze from "../../../assets/images/bronze.svg"
import silver from "../../../assets/images/silver.svg"
import pencil from "../../../assets/images/pencil.svg"
import notebook from "../../../assets/images/notebook.svg"
import illustration from "../../../assets/images/illustration.png"
import edit from "../../../assets/images/edit.png"
import add from "../../../assets/images/add.png"
import { Button } from "../../reusableComponents/Button"
import { Divider } from "../../reusableComponents/Divider"

function Packages() {
  return (
    <Container>
      <Section isGrid={true} gap={"3rem"}>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true} gap={"0"}>
              <Wrapper>
                <ImageContainer>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>Podcast</Tag>
              </Wrapper>
              <Price>$999</Price>
            </Wrapper>
            <Description>
              Introducing our Podcast Service: Elevate your voice with
              professional production and distribution support. Ideal for
              creators seeking to reach a global audience through engaging audio
              content.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true} gap={"0"}>
              <Wrapper>
                <ImageContainer>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Literary <br /> Pitch
                </Tag>
              </Wrapper>
              <Price>$1,599</Price>
            </Wrapper>
            <Description>
              Introducing our Literary Pitch Service: Perfect your pitch with
              expert guidance tailored to catch publishers' attention and
              showcase your manuscript's potential. Ideal for writers ready to
              make a compelling impression in the literary market.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Producers <br /> Pitch (Virtual)
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Producers' Pitch (Virtual) Service: Refine your
              pitch and connect directly with industry professionals. Ideal for
              creators aiming to secure partnerships and funding for their
              projects through expert guidance and virtual networking
              opportunities.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Social Media <br /> Marketing
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Social Media Marketing Service: Amplify your
              presence and engage your audience with targeted strategies
              tailored to your unique voice and goals. Ideal for creators and
              businesses ready to elevate their online impact and grow their
              following effectively.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Book <br /> Reviews
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Book Reviews Service: Gain valuable feedback and
              exposure with professional reviews that highlight the strengths of
              your book. Ideal for authors seeking to enhance credibility and
              attract readers through trusted opinions.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Printed <br /> Materials
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Printed Materials Service: Elevate your brand with
              high-quality printed materials tailored to impress. Ideal for
              businesses and organizations looking to make a memorable impact
              through professional brochures, flyers, and promotional materials.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Book <br /> Trailers
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Book Trailers Service: Bring your story to life
              with captivating visuals and music that intrigue and engage
              potential readers. Ideal for authors seeking to create a
              compelling introduction to their book that sparks curiosity and
              excitement.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  Book <br />
                  Fairs
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Introducing our Book Fairs Service: Showcase your book to a wide
              audience of readers, publishers, and industry professionals at
              prestigious book fairs worldwide. Ideal for authors looking to
              expand their network, gain exposure, and connect with key players
              in the publishing industry.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>
                  New York
                  <br /> Times Book Review
                </Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Elevate your publishing decisions with our New York Times Book
              Review service. Gain critical insights into trending titles,
              author analyses, and market trends essential for strategic
              publishing success. Subscribe today and stay ahead in the
              ever-evolving world of literature.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>Awards</Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Enhance your publishing success with our Award service. Gain
              access to expert guidance, strategic insights, and tailored
              recommendations to maximize your chances of securing prestigious
              literary awards. Partner with us to elevate your books to
              award-winning status.
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>Website</Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Transform your online presence with our comprehensive website
              service for publishing. From user-friendly design to optimized
              content, we specialize in creating websites that showcase your
              books and engage your audience effectively. Elevate your
              publishing journey with a standout digital platform. Contact us
              today to get started!
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold} />
                </ImageContainer>
                <Tag>SEO</Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>
              Drive visibility and growth with our tailored SEO service. We
              specialize in optimizing your online presence to boost book
              discoverability, enhance author visibility, and attract targeted
              readers. Elevate your digital strategy with effective SEO
              techniques. Contact us today to maximize your publishing success!
            </Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
      </Section>
    </Container>
  )
}

export default Packages

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  padding-top: 1rem;

  @media screen and (max-width: 1249px) {
    padding: 2rem 1.5rem;
    gap: 1rem;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 568px) {
    padding: 1.5rem;
  }
`

const Section = styled.section`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media screen and (max-width: 767px) {
    gap: ${({ gap }) => (gap ? "2rem" : "1rem")};
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    gap: 1rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  align-items: ${({ isColumn }) => (isColumn ? "flex-start" : "center")};
  width: 100%;
`

const Tag = styled.h1`
  color: ${({ color }) => (color ? color : "#333")};
  font-size: 2rem;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark4};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3; /* Number of lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-align: justify;

  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`
const Price = styled.h6`
  color: ${props => props.theme.colors.accent6};
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5;
  display: none;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: ${({ height }) => (height ? height : "100px")};
  overflow: hidden;
`
const ImageSvg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`

const SecondaryHeader = styled.h4`
  color: ${({ color }) => (color ? color : "#e85d49")};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.2;
  }
`
